import React, { useEffect, useState } from 'react'
import { Box, Chip, CircularProgress, Grid, Typography } from "@mui/material";
import { useSearchParams } from 'react-router-dom';
import { getRequest, getRequestQa } from '../../utils/ApiRequests';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100%",
    overflow: "hidden"
  },
  form: {
    width: '100%',
    maxWidth: 400,
    marginTop: theme.spacing(1),
    '& > *': {
      marginBottom: theme.spacing(1.5),
    },
  },
  button: {
    backgroundColor: "#ff671f !important"
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));


const ResumeView = ({
  isMobile = false,
  sortListed = [],
  hired = [],
  contacted = false,
  mobile = false,
  targetRef = false,

}) => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get('id');

  useEffect(() => {
    // Open the page in a new tab when the component loads
    if (paramValue) {
      const resumeUrl = `${process.env.REACT_APP_RESUME}/${paramValue}`;
      console.log("Sadf",resumeUrl,paramValue)
      window.open(resumeUrl, '_blank');
    }

  }, [paramValue]);

  return (
    <div className={classes.root}>
      <iframe
        src={`${process.env.REACT_APP_RESUME}/${paramValue}`}
        title="Embedded Website"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      ></iframe>

    </div>
  );
};

export default ResumeView;

